<template>
    <div class="wrapper">
        <img src="https://static.yihu365.cn/img/h5Img/assets/img/ydaoy.png" alt="">
        <button class="btn" @click="goH5Page">点击进入</button>
    </div>
</template>

<script>
    import { useRouter, useRoute } from 'vue-router'
    export default {
        name: "guidePage",
        setup () {
            const goH5Page = ()=> {
                window.location.href='https://mi.yihu365.cn'
            }
            return {
                goH5Page
            }
        }
    }
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
    .wrapper{
        background: #6F3BF3;
        height: 100%;
        overflow: hidden;
        box-sizing: border-box;
        position: relative;
        img{
            width: 100%;
        }
        .btn{
            font-family: PingFangSC-Semibold, PingFang SC;
            border: none;
            position: absolute;
            left:20px;
            bottom: 10%;
            z-index: 1;
            width: 702px;
            height: 82px;
            background: #34C17C;
            box-shadow: 0 7px 0 #0E804F;
            border-radius: 48px;
            font-size: 32px;
            font-weight: 600;
            color: #FFFFFF;
        }
    }
</style>
