import { render } from "./guidePage.vue?vue&type=template&id=4794d080&scoped=true"
import script from "./guidePage.vue?vue&type=script&lang=js"
export * from "./guidePage.vue?vue&type=script&lang=js"

import "./guidePage.vue?vue&type=style&index=0&id=4794d080&rel=stylesheet%2Fscss&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-4794d080"
/* hot reload */
if (module.hot) {
  script.__hmrId = "4794d080"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4794d080', script)) {
    api.reload('4794d080', script)
  }
  
  module.hot.accept("./guidePage.vue?vue&type=template&id=4794d080&scoped=true", () => {
    api.rerender('4794d080', render)
  })

}

script.__file = "src/views/static/guidePage/guidePage.vue"

export default script